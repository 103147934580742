<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.title.label')"
                :label-for="'title'"
              >
                <b-form-input
                  :id="'title'"
                  v-model="item.title"
                  :state="errors && errors.title ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.title">
                  {{ errors.title[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.type.label')"
                label-for="type"
                :state="errors && errors.type ? false : null"
              >
                <v-select
                  id="type"
                  v-model="item.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="type"
                />
                <b-form-invalid-feedback v-if="errors && errors.type">
                  {{ errors.type[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-tabs>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>

              <div class="repeater-form">
                <b-row
                  v-for="(faqItem, index) in item.faq"
                  :id="'faq' + index + language.code"
                  :key="index"
                  ref="row"
                >
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('form.question.label')"
                      :label-for="'faq.' + index + '.question.' + language.code"
                    >
                      <b-form-input
                        :id="'faq.' + index + '.question.' + language.code"
                        v-model="faqItem.question[language.code]"
                        :state="errors && errors['faq.' + index + '.question.' + language.code] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['faq.' + index + '.question.' + language.code]">
                        {{ errors['faq.' + index + '.question.' + language.code][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      :label="$t('form.answer.label')"
                      :label-for="'answer.' + language.code"
                      :state="errors && errors['answer.' + language.code] ? false : null"
                    >
                      <quill-editor
                        :id="'answer.' + language.code"
                        v-model="faqItem.answer[language.code]"
                        :options="editorOptions"
                        :state="errors && errors['answer.' + language.code] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['answer.' + language.code]">
                        {{ errors['answer.' + language.code][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeFaq(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>

                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addFaq"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ $t('general.add') }}</span>
          </b-button>

          <b-row
            v-if="errors && errors.faq"
          >
            <b-col cols="12">
              <b-form-invalid-feedback :state="false">
                {{ errors.faq[0] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>

          <hr>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'seo_pages-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import EditorMixin from '@/mixins/EditorMixin'
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BImg,
  BTable,
  BFormInvalidFeedback,
  BOverlay, BLink, BFormFile, BFormTextarea,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BLink,
    BFormFile,
    BFormTextarea,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormInvalidFeedback,
    vSelect,
    BOverlay,
    quillEditor,
  },
  mixins: [EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      typeOptions: [
        { label: this.$t('administration.seo_page_types.category'), value: 'category' },
        { label: this.$t('administration.seo_page_types.catalog'), value: 'catalog' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/administration/gen_faq/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'gen_faq-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    addFaq() {
      const data = {
        question: {},
        answer: {},
      }

      _.each(this.languages, language => {
        data.question[language.code] = null
        data.answer[language.code] = null
      })

      this.item.faq.push(data)
    },
    removeFaq(index) {
      this.item.faq.splice(index, 1)
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        type: this.item.type,
        faq: this.item.faq,
      }

      this.$http.put(`/api/administration/gen_faq/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'gen_faq-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        type: item.type,
        title: item.title,
        faq: [],
      }

      _.each(item.faq, (faq, index) => {
        data.faq[index] = {
          id: faq.id,
          question: {},
          answer: {},
        }

        _.each(this.languages, language => {
          const translation = _.find(faq.translations, { locale: language.code })

          data.faq[index].question[language.code] = translation ? translation.question : null
          data.faq[index].answer[language.code] = translation ? translation.answer : null
        })
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
